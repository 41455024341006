import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  FacebookIcon,
  LinkedinIcon,
  MediumIcon,
  TwitterIcon,
} from "../../assets/icons";
import { CompanyInfoProps } from "../../types";
import * as Yup from "yup";
import classNames from "classnames";
import styles from "./Contact.module.scss";

export default function Contact() {
  const { t } = useTranslation();

  const CompanyInfo = t("CompanyInfo", { returnObjects: true });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      resetForm();
    },
  });

  return (
    <div className={classNames(styles.ground, "container")}>
      <div className={styles.leftSection}>
        <h1>{t("Contact.header")}</h1>
        <p>{t("Contact.title")}</p>

        <h2>{t("Contact.mail")}</h2>
        {CompanyInfo.filter((i: CompanyInfoProps) => i.desc === "mail").map(
          (item: CompanyInfoProps) => (
            <p key={item.id}>{item.title}</p>
          ),
        )}

        <h2>{t("Contact.phone")}</h2>
        {CompanyInfo.filter((i: CompanyInfoProps) => i.desc === "phone").map(
          (item: CompanyInfoProps) => (
            <p key={item.id}>{item.title}</p>
          ),
        )}

        <h2>{t("Contact.social")}</h2>
        <div className={styles.socialNetworks}>
          <span className={styles.icons}>
            <img src={FacebookIcon} alt="" />
          </span>
          <span className={styles.icons}>
            <img src={LinkedinIcon} alt="" />
          </span>
          <span className={styles.icons}>
            <img src={MediumIcon} alt="" />
          </span>
          <span className={styles.icons}>
            <img src={TwitterIcon} alt="" />
          </span>
        </div>
      </div>

      <div className={styles.rightSection}>
        <form className={styles.form}>
          <label>{t("Contact.name")}</label>
          <input
            id="name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />

          <label>{t("Contact.mail")}</label>
          <input
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />

          <label>{t("Contact.phone")}</label>
          <input
            id="phone"
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
          />

          <label>{t("Contact.message")}</label>
          <textarea
            id="message"
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
        </form>

        <button
          onClick={() => formik?.handleSubmit()}
          type="submit"
          className={styles.button}
        >
          {t("Contact.btn")}
        </button>
      </div>
    </div>
  );
}
