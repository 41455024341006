import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CompanyInfoProps, NavigationItemsProps } from "../../types";
import logo from "./../../assets/logo.svg";
import classNames from "classnames";
import styles from "./Nav.module.scss";

interface NavProps {
  className?: string;
}

export default function Nav({ className }: NavProps) {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLang: string) => {
    i18n.changeLanguage(newLang);
  };

  const NavigationItems: any = t("NavigationItems", { returnObjects: true });
  const CompanyInfo: any = t("CompanyInfo", { returnObjects: true });

  return (
    <div className={classNames(styles.ground, className)}>
      <img src={logo} alt="logo" className={styles.logo} />

      <div className={styles.sidebar}>
        <div className={styles.topField}>
          <div className={styles.infoBlock}>
            {CompanyInfo.map((item: CompanyInfoProps) => (
              <div key={item.id} className={styles.info}>
                <img className={styles.icon} src={item.icon} alt="" />
                <p className={styles.title}>{item.title}</p>
              </div>
            ))}
          </div>

          <div className={styles.changeLang}>
            <img
              onClick={() => handleLanguageChange("ru")}
              className={styles.btn}
              src="https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg"
              alt="rusFlag"
            />
            <img
              onClick={() => handleLanguageChange("uz")}
              className={styles.btn}
              src="https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg"
              alt="uzbFlag"
            />
            {/* 
            // English
              <img
              onClick={() => handleLanguageChange("en")}
              className={styles.btn}
              src="https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
              alt="UKFlag"
            /> */}
          </div>
        </div>

        <div className={styles.bottomField}>
          <ul className={styles.navigation}>
            {NavigationItems.filter(
              (i: NavigationItemsProps) => i.path !== "blog",
            ).map((list: NavigationItemsProps) => (
              <li key={list.name}>
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? styles.pending : isActive ? styles.active : ""
                  }
                  to={list.path}
                >
                  {list.name}
                </NavLink>
              </li>
            ))}
          </ul>

          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? styles.pending
                : isActive
                ? classNames(styles.blog, styles.active)
                : styles.blog
            }
            to={NavigationItems[NavigationItems.length - 1].path}
          >
            {NavigationItems[NavigationItems.length - 1].name}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
