import styles from "./VQButton.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

type VQButtonTypes = "dark" | "light";

export interface VQButtonProps {
  children?: React.ReactNode;
  className?: string;
  path: string;
  type?: VQButtonTypes;
  onClick?: () => void;
}

export default function VQButton({
  className,
  children,
  path,
  type = "light",
  onClick,
}: VQButtonProps) {
  return (
    <Link
      className={classNames(styles.button, className, {
        [styles.dark]: type === "dark",
      })}
      onClick={onClick}
      to={path}
    >
      {children}
    </Link>
  );
}
