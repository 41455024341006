import { useTranslation } from "react-i18next";
import VQLinkButton from "../VQLinkButton/VQLinkButton";
import photo from "./../../assets/images/director_photo.png";
import { QuoteIcon } from "../../assets/icons";
import classNames from "classnames";
import styles from "./CommentCard.module.scss";

export default function CommentCard() {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.ground, "container")}>
      <div className={styles.leftSection}>
        <h1>{t("Comment.header")}</h1>
        <p>{t("Comment.title")}</p>
        <VQLinkButton path="/about" className={styles.link}>
          {t("Comment.btn")}
        </VQLinkButton>
      </div>

      <div className={styles.rightSection}>
        <h1>{t("Comment.comment")}</h1>

        <div className={styles.bottomField}>
          <img className={styles.userImage} src={photo} alt="" />
          <span>
            <p className={styles.name}>{t("Comment.name")}</p>
            <p className={styles.position}>{t("Comment.position")}</p>
          </span>

          <img className={styles.quote} src={QuoteIcon} alt="icon" />
        </div>
      </div>
    </div>
  );
}
