import { useTranslation } from "react-i18next";
import CommentCard from "../../components/CommentCard/CommentCard";
import ServicesCard from "../../components/ServicesCard/ServicesCard";
import VQButton from "../../components/VQButton/VQButton";
import VQLinkButton from "../../components/VQLinkButton/VQLinkButton";
import { CompanyInfoProps, ServiceProps } from "../../types";
import classNames from "classnames";
import styles from "./Dashboard.module.scss";

export default function Dashboard() {
  const { t } = useTranslation();

  const ServicesData = t("Services", { returnObjects: true });
  const CompanyInfo = t("CompanyInfo", { returnObjects: true });

  return (
    <div className={styles.ground}>
      <div className={classNames(styles.topSection)}>
        <div className={classNames(styles.topSectionContent, "container")}>
          <h2>{t("Dashboard.top_header")}</h2>
          <h1>{t("Dashboard.top_title")}</h1>
          <p>{t("Dashboard.top_text")}</p>
          <VQButton path="/contact" className={styles.button} type="light">
            {t("Dashboard.top_btn_text")}
          </VQButton>
        </div>
      </div>

      <div className={classNames(styles.aboutSection)}>
        <div className={classNames(styles.content, "container")}>
          <span>
            <h1>{t("Dashboard.about_header")}</h1>
            <p>{t("Dashboard.about_title")}</p>
          </span>

          <VQButton path="/about" type="dark">
            {t("Dashboard.about_btn_text")}
          </VQButton>
        </div>
      </div>

      <div className={styles.helpSection}>
        <div className={styles.leftBlock}>
          <h1 className={styles.title}>{t("Dashboard.help_left_header")}</h1>
          <span className={styles.imgField}>
            <img
              className={styles.image}
              src="https://ecostandardgroup.ru/upload/medialibrary/92d/92d194f89c919a3c958b3edda6c4cce3.jpg"
              alt="Img"
            />
          </span>
        </div>

        <div className={styles.rightBlock}>
          <h1>{t("Dashboard.help_right_header")}</h1>
          <p>{t("Dashboard.help_title")}</p>

          <VQButton path="/contact">{t("Dashboard.help_btn_text")}</VQButton>
        </div>
      </div>

      <div className={classNames(styles.industries, "container")}>
        <h1 className={styles.title}>{t("Dashboard.service_header")}</h1>

        <div className={styles.cardsField}>
          {ServicesData.map((service: ServiceProps) => (
            <ServicesCard
              key={service.id}
              icon={service.icon}
              title={service.title}
              path={"/services"}
            />
          ))}

          <div className={styles.seeAll}>
            <VQLinkButton className={styles.link} path={"/services"}>
              {t("Dashboard.service_btn_text")}
            </VQLinkButton>
          </div>
        </div>
      </div>

      <CommentCard />

      <div className={styles.numberSection}>
        {CompanyInfo.filter(
          (item: CompanyInfoProps) => item.desc === "phone",
        ).map((num: CompanyInfoProps) => (
          <span className={styles.numberField} key={num.id}>
            <p className={styles.callUs}>{t("Dashboard.call")}</p>
            <p className={styles.phone}>{num.title}</p>
          </span>
        ))}
      </div>
    </div>
  );
}
