import { t } from "i18next";
import VQLinkButton from "../VQLinkButton/VQLinkButton";
import classNames from "classnames";
import styles from "./ServicesCard.module.scss";

export interface ServicesCardProps {
  className?: string;
  icon?: any;
  title: string;
  path: string;
}

export default function ServicesCard({
  className,
  icon,
  title,
  path,
}: ServicesCardProps) {
  return (
    <div className={classNames(styles.ground, className)}>
      <div className={styles.iconField}>
        {icon && <img src={icon} alt={icon} className={styles.icon} />}
      </div>

      <h1 className={styles.title}>{title}</h1>

      <VQLinkButton path={path}>{t("learn_more")}</VQLinkButton>
    </div>
  );
}
