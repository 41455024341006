import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceProps } from "../../types";
import classNames from "classnames";
import styles from "./Services.module.scss";

export default function Services() {
  const [active, setActive] = useState("1");
  const { t } = useTranslation();

  const ServicesData = t("Services", { returnObjects: true });

  const activeService = ServicesData.filter(
    (item: ServiceProps) => item.id === active,
  );

  return (
    <div className={classNames(styles.ground, "container")}>
      <div className={styles.topSection}>
        {ServicesData.map((service: ServiceProps) => (
          <div
            key={service.id}
            className={classNames(
              styles.servicePreview,
              active === service.id && styles.active,
            )}
            onClick={() => setActive(service.id)}
          >
            <span>
              <img src={service.icon} alt="" />
            </span>
            <h1>{service.title}</h1>
          </div>
        ))}
      </div>

      <div className={styles.content}>
        {activeService.map((ser: ServiceProps) => (
          <div className={styles.contentField} key={ser.id}>
            <h1>{ser.title}</h1>
            <p className={styles.description}>{ser.description}</p>
            <p>{ser.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
