import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Shadow } from "../../assets/icons";
import { AboutInfoProps } from "../../types";
import styles from "./About.module.scss";

export default function About() {
  const { t } = useTranslation();

  const AboutInfo = t("About.info", { returnObjects: true });

  const title = t("About.title");

  return (
    <div className={classNames(styles.ground, "container-xxl")}>
      <div className={styles.topBar}>
        <div className={styles.figure} />
        <h1>{t("About.header")}</h1>
      </div>

      <div className={styles.content}>
        <h1 className={styles.header}>
          <p>{title}</p>
          <span>
            <img src={Shadow} alt="" />
          </span>
        </h1>

        <div className={styles.infoField}>
          {AboutInfo.map((info: AboutInfoProps) => (
            <div key={info.id} className={styles.info}>
              <img src={info.icon} alt="" />
              <p>{info.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
