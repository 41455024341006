import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  LinkedinIcon,
  MediumIcon,
  TwitterIcon,
} from "../../assets/icons";
import { goToTop } from "../../lib/helpers/goToTop";
import VQButton from "../VQButton/VQButton";
import logo from "./../../assets/logo.svg";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import {
  CompanyInfoProps,
  NavigationItemsProps,
  ServiceProps,
} from "../../types";

export interface FooterProps {
  className?: string;
}

export default function Footer({ className }: FooterProps) {
  const { t } = useTranslation();

  const NavigationItems = t("NavigationItems", { returnObjects: true });
  const ServicesData = t("Services", { returnObjects: true });
  const CompanyInfo = t("CompanyInfo", { returnObjects: true });

  return (
    <div className={classNames(styles.footer, className)}>
      <div className={styles.ground}>
        <div className={styles.logoSection}>
          <img src={logo} alt="" />
          <VQButton path="/contact">{t("Footer.career")}</VQButton>
        </div>

        <div className={styles.linkSection}>
          <h1 className={styles.header}>{t("Footer.links")}</h1>

          <ul className={styles.itemsField}>
            {NavigationItems.map((item: NavigationItemsProps) => (
              <li key={item.id} className={styles.list} onClick={goToTop}>
                <Link to={item.path}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.serviceSection}>
          <h1 className={styles.header}>{t("Footer.services")}</h1>

          <ul className={styles.itemsField}>
            {ServicesData.slice(0, 5).map((item: ServiceProps) => (
              <li key={item.id} className={styles.list} onClick={goToTop}>
                <Link to={item.title}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.sertificateSection}>
          <h1 className={styles.header}>{t("Footer.certificates")}</h1>
        </div>

        <div className={styles.rightSection}>
          <h1 className={styles.header}>{t("Footer.social")}</h1>

          <div className={styles.socialNetworks}>
            <span className={styles.icons}>
              <img src={FacebookIcon} alt="" />
            </span>
            <span className={styles.icons}>
              <img src={LinkedinIcon} alt="" />
            </span>
            <span className={styles.icons}>
              <img src={MediumIcon} alt="" />
            </span>
            <span className={styles.icons}>
              <img src={TwitterIcon} alt="" />
            </span>
          </div>

          <h1 className={styles.header}>{t("Footer.getTouch")}</h1>

          <ul className={styles.infoField}>
            {CompanyInfo.map((info: CompanyInfoProps) => (
              <li key={info.id}>
                <img src={info.icon} alt="" />
                {info.title}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.bottomGround}>
        <h1 className={styles.title}>
          © Copyright by *** - All right reserved.
        </h1>

        <div className={styles.rightBlock}>
          <h1 className={styles.header}>PRIVACY POLICY</h1>
          <p className={styles.header}>|</p>
          <h1 className={styles.header}>COOKIE POLICY</h1>
        </div>
      </div>
    </div>
  );
}
