import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Routes, Route } from "react-router";
import Nav from "./components/Nav/Nav";
import Dashboard from "./pages/Dashboard/Dashboard";
import Services from "./pages/Services/Services";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";

import translationRU from "./lib/locales/ru/translation.json";
import translationUZ from "./lib/locales/uz/translation.json";
import "./App.scss";

const resources = {
  uz: {
    translation: translationUZ,
  },
  ru: {
    translation: translationRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",
  fallbackLng: "ru",
  interpolation: {
    escapeValue: true,
  },
});

function App() {
  return (
    <div className="app">
      <Nav />

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Dashboard />} />
      </Routes>

      <Footer className="footer" />
    </div>
  );
}

export default App;
