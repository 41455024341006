import styles from "./VQLinkButton.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AngleRightIcon } from "../../assets/icons";
import { goToTop } from "../../lib/helpers/goToTop";

export interface VQLinkButtonProps {
  children?: React.ReactNode;
  path: string;
  className?: string;
}

export default function VQLinkButton({
  className,
  path,
  children,
}: VQLinkButtonProps) {
  return (
    <Link
      onClick={goToTop}
      to={path}
      className={classNames(styles.button, className)}
    >
      {children}
      <img src={AngleRightIcon} alt="icon" className={styles.icon} />
    </Link>
  );
}
